import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore.ts"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore.ts"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore.ts"

const ActivityRangeSelector = () => {
  const currentCardioOffsetDays = useHeartRateSummaryStore((state) => state.currentOffsetDays)
  const setCurrentHeartRateOffsetDays = useHeartRateSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentHRVOffsetDays = useHRVSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentActiveCaloriesOffsetDays = useActiveCaloriesSummaryStore((state) => state.setCurrentOffsetDays)

  const dayRange = 30

  const getRangeStartText = () => {
    return `${dayRange + currentCardioOffsetDays}d ago`
  }

  const getRangeEndText = () => {
    if (currentCardioOffsetDays === 0) {
      return "Today"
    }
    return `${currentCardioOffsetDays}d ago`
  }

  const goToPrev = () => {
    setCurrentHeartRateOffsetDays(currentCardioOffsetDays + dayRange)
    setCurrentHRVOffsetDays(currentCardioOffsetDays + dayRange)
    setCurrentActiveCaloriesOffsetDays(currentCardioOffsetDays + dayRange)
  }

  const goToNext = () => {
    if (currentCardioOffsetDays - dayRange < 0) {
      return
    }
    setCurrentHeartRateOffsetDays(currentCardioOffsetDays - dayRange)
    setCurrentHRVOffsetDays(currentCardioOffsetDays - dayRange)
    setCurrentActiveCaloriesOffsetDays(currentCardioOffsetDays - dayRange)
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="text-sm flex flex-row items-center font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default ActivityRangeSelector
