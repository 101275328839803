import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useDaylightTimeSummaryStore from "../summary/store/DaylightSummaryStore.ts"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore.ts"
import useLocationInsightStore from "./store/LocationInsightStore.ts"

const LocationRangeSelector = () => {
  const currentLocationOffsetDays = useLocationInsightStore((state) => state.currentOffsetDays)
  const setCurrentLocationOffsetDays = useLocationInsightStore((state) => state.setCurrentOffsetDays)
  const setCurrentHomeTimeOffsetDays = useHomeTimeSummaryStore((state) => state.setCurrentOffsetDays)
  const currentLimitDays = useLocationInsightStore((state) => state.currentLimitDays)
  const setCurrentDaylightOffsetDays = useDaylightTimeSummaryStore((state) => state.setCurrentOffsetDays)

  const getRangeStartText = () => {
    return `${currentLimitDays + currentLocationOffsetDays}d ago`
  }

  const getRangeEndText = () => {
    if (currentLocationOffsetDays === 0) {
      return "Today"
    }
    return `${currentLocationOffsetDays}d ago`
  }

  const goToPrev = () => {
    setCurrentLocationOffsetDays(currentLocationOffsetDays + currentLimitDays)
    setCurrentHomeTimeOffsetDays(currentLocationOffsetDays + currentLimitDays)
    setCurrentDaylightOffsetDays(currentLocationOffsetDays + currentLimitDays)
  }

  const goToNext = () => {
    if (currentLocationOffsetDays - currentLimitDays < 0) {
      return
    }
    setCurrentLocationOffsetDays(currentLocationOffsetDays - currentLimitDays)
    setCurrentHomeTimeOffsetDays(currentLocationOffsetDays - currentLimitDays)
    setCurrentDaylightOffsetDays(currentLocationOffsetDays - currentLimitDays)
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="text-sm flex items-center flex-row font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default LocationRangeSelector
