import { ColumnDef } from "@tanstack/react-table"
import { Link } from "react-router-dom"

import AddAssignmentAction from "@/features/Home/components/TableActionButton/AddAssignmentAction"
import CheckPermissionAction from "@/features/Home/components/TableActionButton/CheckPermissionAction"
import SeeOverviewAction from "@/features/Home/components/TableActionButton/SeeOverviewAction"

import { DataTableColumnHeader } from "@components/Tables/DataTable.tsx"
import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"
import UsersTableDropdownMenu from "@components/Widgets/UsersTable/UsersTableDropdownMenu.tsx"

import ActionButtonsGroup from "@features/Home/components/TableActionButton/ActionButtonsGroup.tsx"

import SeeInsightReportAction from "../TableActionButton/SeeInsightReportAction"

const ClinicianClients = () => {
  const columns: ColumnDef<ListUser>[] = [
    {
      id: "Display Name",
      accessorKey: "display_name",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Name"} />,
      cell: ({ row }) => {
        const user = row.original
        return (
          <Link to={`/insight/${user.user_id}`}>
            <div className="flex items-center">
              <span>{user.display_name}</span>
            </div>
          </Link>
        )
      },
    },
    {
      id: "Sex",
      accessorKey: "sex",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Sex"} />,
    },
    {
      id: "Age",
      accessorKey: "age",
      header: ({ column }) => <DataTableColumnHeader column={column} title={"Age"} />,
    },
    {
      id: "ActionsButton",
      accessorKey: "Quick Access",
      cell: ({ row }) => (
        <ActionButtonsGroup>
          <SeeInsightReportAction row={row} />
          <SeeOverviewAction row={row} />
          <AddAssignmentAction row={row} />
          <CheckPermissionAction row={row} />
        </ActionButtonsGroup>
      ),
    },
    {
      id: "Actions",
      accessorKey: "Actions",
      cell: ({ row }) => <UsersTableDropdownMenu row={row} useCase={"clinician_home"} />,
    },
  ]

  return <UsersTable title={"My Clients"} endpoint={"/users/associated"} columns={columns} />
}
export default ClinicianClients
