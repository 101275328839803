import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore"
import useDaylightSummaryStore from "../summary/store/DaylightSummaryStore"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import CardioSection from "./CardioSection"
import LocationSection from "./LocationSection"
import SleepAndStepsSection from "./SleepAndStepsSection"
import useActivityInsightStore from "./store/ActivityInsightStore"
import useLocationInsightStore from "./store/LocationInsightStore"

const ActivityPage: React.FC = () => {
  // Import the setOffsetDays function for each section
  const setSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setStepOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)
  const setExerciseOffsetDays = useExerciseSummaryStore((state) => state.setCurrentOffsetDays)
  const setActivityOffsetDays = useActivityInsightStore((state) => state.setCurrentOffsetDays)

  const setHeartRateOffsetDays = useHeartRateSummaryStore((state) => state.setCurrentOffsetDays)
  const setHRVOffsetDays = useHRVSummaryStore((state) => state.setCurrentOffsetDays)
  const setActiveCaloriesOffsetDays = useActiveCaloriesSummaryStore((state) => state.setCurrentOffsetDays)

  const setHomeTimeOffsetDays = useHomeTimeSummaryStore((state) => state.setCurrentOffsetDays)
  const setDaylightOffsetDays = useDaylightSummaryStore((state) => state.setCurrentOffsetDays)
  const setLocationInsightOffsetDays = useLocationInsightStore((state) => state.setCurrentOffsetDays)

  // Set the offset days to 0
  setSleepOffsetDays(0)
  setStepOffsetDays(0)
  setExerciseOffsetDays(0)
  setActivityOffsetDays(0)

  setHeartRateOffsetDays(0)
  setHRVOffsetDays(0)
  setActiveCaloriesOffsetDays(0)

  setHomeTimeOffsetDays(0)
  setDaylightOffsetDays(0)
  setLocationInsightOffsetDays(0)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Activity</h3>
      </div>

      <SleepAndStepsSection />
      <CardioSection />
      <LocationSection />
    </div>
  )
}

export default ActivityPage
