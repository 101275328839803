import { create } from "zustand"

interface DaylightSummaryStore {
  currentDaylightResponse: DaylightSummaryResponse | null
  setCurrentDaylightResponse: (response: DaylightSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useDaylightSummaryStore = create<DaylightSummaryStore>((set) => ({
  currentDaylightResponse: null,
  setCurrentDaylightResponse: (currentDaylightResponse) => set({ currentDaylightResponse }),

  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),

  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useDaylightSummaryStore
